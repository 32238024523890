import { NUMBER_OF_COMPANIES, LOGO_COMPANY_FIELDS } from "./logos-const";
export default {
    numberOfCompanies: NUMBER_OF_COMPANIES,
    grayscale: false,
    showAllLogos: false,
    companyField: LOGO_COMPANY_FIELDS.GENERAL,
    logosScale: 1,
    useSingleLine: false,
    paddingInlined: "0px"
};
